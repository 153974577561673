import axios, { AxiosResponse } from 'axios';
import Axios from "axios";
import Vue from 'vue'

const endpointRegister: string = "auth/register";
const endpointLogin: string = "auth/login";
const endpointValidate: string = "auth/validate";

// Use axiosAuthInstance to override default 401 error handling behaviour
const axiosAuthInstance = axios.create()
axiosAuthInstance.defaults.baseURL = process.env.VUE_APP_API_URL
axiosAuthInstance.interceptors.response.use(
    response => {
        return response
    },
    error => {
        const errorCode: number = error.response.status;
        if (errorCode == 401) {
            return Promise.reject(new Unauthorized())
        } else if (errorCode == 409) {
            return Promise.reject(new Conflict())
        } else if (errorCode == 404) {
            return Promise.reject(new NotFound())
        } else {
            return error
        }
    })

const handleAuthResponse = (email: string, response: AxiosResponse<AuthResponse>) => {
    if (response.data.token) {
        Vue.$cookies.set('token', response.data.token, '72h', undefined, window.location.hostname);
        Vue.$cookies.set('email', email, '72h', undefined, window.location.hostname);
        Axios.defaults.headers.common = { 'Authorization': `Bearer ${response.data.token}` }
    }
    return response.data;
}

class AuthService {
    login(email: string, password: string): Promise<AuthResponse> {
        const req = new LoginRequest(email, password);
        return axiosAuthInstance
            .post<AuthResponse>(endpointLogin, req)
            .then(response => handleAuthResponse(email, response))
            .catch(Promise.reject);
    }

    logout() {
       Vue.$cookies.remove('token', undefined, window.location.hostname)
    }

    validate(email: string, inviteCode: string): Promise<any> {
        const req = new ValidateInviteRequest(email, inviteCode);
        return axiosAuthInstance
            .post<any>(endpointValidate, req)
            .then(_ => Promise.resolve(new ValidationSuccess()))
            .catch(Promise.reject);
    }

    register(email: string, password: string, inviteCode: string): Promise<AuthResponse> {
        const req = new RegisterRequest(email, password, inviteCode);
        return axiosAuthInstance
            .post<AuthResponse>(endpointRegister, req)
            .then(response => handleAuthResponse(email, response))
            .catch(Promise.reject);
    }
}

export class RegisterRequest {
    email: string;
    password: string;
    inviteCode: string;

    constructor(email: string, password: string, inviteCode: string) {
        this.email = email;
        this.password = password;
        this.inviteCode = inviteCode;
    }
}

export class LoginRequest {
    email: string;
    password: string;

    constructor(email: string, password: string) {
        this.email = email;
        this.password = password;
    }
}

export class ValidateInviteRequest {
    email: string;
    inviteCode: string;

    constructor(email: string, inviteCode: string) {
        this.email = email;
        this.inviteCode = inviteCode;
    }
}

export class ValidationSuccess { }

export class Unauthorized { }
export class Conflict { }
export class NotFound { }

export interface AuthResponse {
    token: string
}

export default new AuthService();
