













































import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class App extends Vue {
  drawer = false;

  views = [
    { title: "Home", icon: "mdi-home", path: "/home" },
    { title: "About", icon: "mdi-card-account-mail", path: "/about" },
    { title: "List", icon: "mdi-playlist-edit", path: "/list" },
    { title: "Listing Sessions", icon: "mdi-format-list-bulleted", path: "/listing-sessions" },
    { title: "Inventory", icon: "mdi-warehouse", path: "/inventory" }
  ];

  get statusLoggedIn() {
    return this.$store.getters["auth/getStatusLoggedIn"];
  }
  get email() {
    return this.$store.getters["auth/getEmail"];
  }

  logout() {
    this.$store
      .dispatch("auth/logout")
      .then(_ => console.log("logged out"))
      .then(_ => this.$router.push("/login"))
      .catch(err => console.log(err));
  }
}
