import AuthService from '@/services/auth';
import { RegisterRequest, LoginRequest } from '@/services/auth';
import { ActionTree, GetterTree, MutationTree } from "vuex";

class State {
    token: string | null = null;
    email: string | null = null;
    status: {
        loggedIn: boolean;
    };
    constructor(token: string | null, status: { loggedIn: boolean }) {
        this.token = token;
        this.status = status;
    };
}

const getters = <GetterTree<State, any>>{
    getToken: (state: State) => {
        return state.token
    },
    getEmail: (state: State) => {
        return state.email
    },
    getStatusLoggedIn: (state: State): boolean => {
        return state.status.loggedIn;
    }
};

const mutations = <MutationTree<State>>{
    loginSuccess(state: State, user: User) {
        state.status.loggedIn = true;
        state.token = user.token;
        state.email = user.email;
    },
    loginFailure(state: State) {
        state.status.loggedIn = false;
        state.token = null;
    },
    logout(state: State) {
        state.status.loggedIn = false;
        state.email = null;
        state.token = null;
    },
    registerSuccess(state: State, user: User) {
        state.status.loggedIn = true;
        state.token = user.token;
        state.email = user.email;
    },
    registerFailure(state: State) {
        state.status.loggedIn = false;
    }
};

const actions = <ActionTree<State, any>>{
    login: async function (context, req: LoginRequest) {
        return AuthService.login(req.email, req.password).then(
            response => {
                context.commit('loginSuccess', new User(req.email, response.token));
                return Promise.resolve(response);
            },
            error => {
                context.commit('loginFailure');
                return Promise.reject(error);
            }
        );
    },
    loadUser: async function (context, user: User) {
        context.commit('loginSuccess', user);
    },
    logout: async function (context) {
        AuthService.logout();
        context.commit('logout');
    },
    register: async function (context, req: RegisterRequest) {
        return AuthService.register(req.email, req.password, req.inviteCode).then(
            response => {
                context.commit('registerSuccess', new User(req.email, response.token));
                return Promise.resolve(response);
            },
            error => {
                context.commit('registerFailure');
                return Promise.reject(error);
            }
        );
    },
};

export class User {
    email: string;
    token: string;
    constructor(email: string, token: string) {
        this.email = email;
        this.token = token;
    }
}

const AuthModule = {
    namespaced: true,
    state: new State(null, { loggedIn: false }),
    mutations: mutations,
    actions: actions,
    getters: getters
};

export default AuthModule;
