import { ActionTree, GetterTree, MutationTree } from "vuex";
import { ListingSession } from "@/services/session";
import SessionService from '@/services/session';
import ItemService from '@/services/items';
import { Item } from '../services/model/item'

class State {
    sessions: Array<Session>;
    activeSessionId?: string;

    constructor(sessions: Array<Session>, activeSessionId?: string) {
        this.sessions = sessions;
        this.activeSessionId = activeSessionId;
    }
}

export class Session {
    id?: string
    createdAt: string
    itemCount: number
    soldCount: number
    items: Array<Item>

    constructor(items: Array<Item>, createdAt: string, itemCount: number, soldCount: number, id?: string) {
        this.id = id;
        this.createdAt = createdAt;
        this.items = items;
        this.itemCount = itemCount;
        this.soldCount = soldCount;
    }
}

const getters = <GetterTree<State, any>>{
    getAllItems: (state: State) => {
        return state.sessions.map(session => session.items)
    },
    getSession: (state: State) => (id: string) => {
        return state.sessions.find(session => session.id === id)
    },
    getActiveSessionId: (state: State) => {
        return state.activeSessionId;
    },
    // TODO: do not return items as well - expensive?
    getSessions: (state: State) => {
        return state.sessions.sort((s1: Session, s2: Session) => {
            return Date.parse(s2.createdAt) - Date.parse(s1.createdAt)
        });
    },
    getActiveSessionItemCount: (state: State) => {
        const activeSessionIndex = state.sessions.findIndex(session => session.id == state.activeSessionId)
        if (activeSessionIndex !== -1) {
            return state.sessions[activeSessionIndex].items.length
        }
    },
    getSessionItems: (state: State) => (sessionId: string): Array<Item> => {
        const sessionIndex = state.sessions.findIndex(session => session.id == sessionId)
        if (sessionIndex !== -1) {
            return state.sessions[sessionIndex].items
        } else {
            console.info("No session to get items for.")
            return []
        }
    },
    getActiveSessionItems: (state: State) => {
        const activeSessionIndex = state.sessions.findIndex(session => session.id == state.activeSessionId);
        if (activeSessionIndex !== -1) {
            return state.sessions[activeSessionIndex].items
        } else {
            console.info("No active session to get items for.")
            return []
        }
    }
};

const mutations = <MutationTree<State>>{
    addItem: (state: State, item: Item) => {
        const itemSessionId = item.listingSessionID;
        const sessionIndex: number = state.sessions.findIndex(session => session.id == itemSessionId);
        if (sessionIndex !== -1) {
            state.sessions[sessionIndex].items = [item].concat(state.sessions[sessionIndex].items)
        } else {
            // no session exist, so createdAt is an empty string
            console.log("No session exist for adding this item to.")
            const session = new Session([item], "", 0, 0, item.listingSessionID)
            state.sessions = state.sessions.concat(session)
        }
    },
    setSessionItems: (state: State, sessionItems: { items: Item[], sessionId?: string }) => {
        const itemSessionId = sessionItems.sessionId;
        const sessionIndex: number = state.sessions.findIndex(session => session.id == itemSessionId);
        if (sessionIndex !== -1) {
            state.sessions[sessionIndex].items = sessionItems.items
        } else {
            // no session exist, so createdAt is an empty string
            console.log("No session exist for adding these items to.")
            const session = new Session(sessionItems.items, "", 0, 0, sessionItems.sessionId)
            state.sessions = state.sessions.concat(session)
        }
    },
    loadSessions: (state: State, sessions: Session[]) => {
        const currentSessionIds = state.sessions.map(session => session.id)
        sessions.forEach((session: Session) => {
            const sessionIndex = state.sessions.findIndex(stateSession => stateSession.id == session.id)
            if (sessionIndex !== -1) {
                // if the session already exists in state, only update the metadata of the session
                console.log("session already exist - only updating metadata")
                state.sessions[sessionIndex].createdAt = session.createdAt
                state.sessions[sessionIndex].itemCount = session.itemCount
                state.sessions[sessionIndex].soldCount = session.soldCount
            } else {
                // if the session doesn't exist in state, create a new session (empty items, id, createdAt)
                state.sessions = state.sessions.concat(session)
            }
        })
    },
    setActiveSessionId: (state: State, listingSession: ListingSession) => {
        state.activeSessionId = listingSession.id;
    },
    setActiveSession: (state: State, listingSession: ListingSession) => {
        const session = new Session(Array(), listingSession.createdAt, listingSession.itemCount, listingSession.soldCount, listingSession.id);
        state.sessions = state.sessions.concat(session);
    }
};

const actions = <ActionTree<State, any>>{
    createItem: async (context, item: Item) => {
        ItemService.createItem(item).then(
            response => {
                context.commit('addItem', item)
            },
            error => error
        )
    },
    populate: async (context, sessionId) => {
        return SessionService.getItems(sessionId)
            .then((items: Array<Item>) => {
                context.commit('setSessionItems', { items, sessionId })
            })
    },
    getLatestSession: async (context) => {
        return SessionService.getLatestSession()
            .then((listingSession: ListingSession) => {
                context.commit('setActiveSessionId', listingSession);
                return Promise.resolve(listingSession);
            });
    },
    createSession: async (context) => {
        return SessionService.createSession()
            .then((listingSession: ListingSession) => {
                context.commit('setActiveSessionId', listingSession)
                context.commit('setActiveSession', listingSession)
                return Promise.resolve(listingSession);
            });
    },
    loadSessions: async (context) => {
        return SessionService.getSessions()
            .then((listingSessions: ListingSession[]) => {
                const sessions = listingSessions.map(listingSession =>
                    new Session([], listingSession.createdAt, listingSession.itemCount, listingSession.soldCount, listingSession.id)
                )
                return context.commit('loadSessions', sessions)
            }).then(_ => {
                return context.dispatch("getLatestSession")
            })
    }
};

const SessionModule = {
    namespaced: true,
    state: new State([], undefined),
    getters: getters,
    mutations: mutations,
    actions: actions
};

export default SessionModule;
