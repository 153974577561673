import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Axios from 'axios'
import { User } from "@/store/auth.module";
import { Component } from 'vue-property-decorator'
import VueCookies from 'vue-cookies';

console.log(`
 _   _             __   _   _        __ 
| | (_)           / _| | | (_)      / _|
| |_ _  ___   ___| |_  | |_ _  __ _| |_ 
| __| |/ _ \\ / _ \\  _| | __| |/ _' |  _|
| |_| | (_) |  __/ |   | |_| | (_| | |  
 \\__| |\\___/ \\___|_|    \\__| |\\__,_|_|  
   _/ |                   _/ |          
  |__/                   |__/`);

Axios.defaults.baseURL = process.env.VUE_APP_API_URL

Axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.error(error.response.status);
    if (error.response.status == 401) {
      console.warn("User is not logged in.")
      store.dispatch('auth/logout');
      router.push('/login');
    }
  })

Vue.use(VueCookies);

const token = Vue.$cookies.get('token')
const email = Vue.$cookies.get('email')

if (email && token) {
  store.dispatch("auth/loadUser", new User(email, token));
}

Component.registerHooks(['validations'])

Vue.config.productionTip = false

new Vue({
  el: '#app',
  store: store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app');
