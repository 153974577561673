import { ActionTree, GetterTree, MutationTree } from "vuex";
import ItemService, { PaginatedResponse, pageSize } from '@/services/items';
import { Item } from '../services/model/item'

class State {
    totalCount: number
    offset: number
    limit: number
    pages: Array<Page>

    constructor(totalCount: number, offset: number, limit: number, pages: Array<Page>) {
        this.totalCount = totalCount;
        this.offset = offset;
        this.limit = limit;
        this.pages = pages;
    }
};

export class Page {
    items: Item[];
    index: number;

    constructor(items: Item[], index: number = 0) {
        this.items = items;
        this.index = index;
    }
};

const getters = <GetterTree<State, any>>{
    numberOfPages: (state: State) => {
        return numberOfPages(state.totalCount);
    },
    getPage: (state: State) => (pageNr: number) => {
        const indexOfPage = state.pages.findIndex(page => page.index == pageNr - 1);
        return state.pages[indexOfPage].items
    },
    totalItemCount: (state: State) => {
        return state.totalCount;
    },
    noLoadedItems: (state: State) => {
        return state.pages.length == 0;
    },
    loadedPages: (state: State) => {
        return state.pages.length;
    }
};

const mutations = <MutationTree<State>>{
    addPage: (state: State, paginatedResponse: PaginatedResponse) => {
        const currentPageIndex = getCurrentPageIndex(paginatedResponse.offset);
        const page = new Page(paginatedResponse.items, currentPageIndex);
        state.offset = paginatedResponse.offset;
        state.limit = paginatedResponse.limit;
        state.totalCount = paginatedResponse.totalCount;
        state.pages = state.pages.concat(page);
    },
    updateItem: (state: State, updatedItem: Item) => {
        const currentPageIndex = getCurrentPageIndex(state.offset);
        const pageIndex = state.pages.findIndex(page => page.index == currentPageIndex);
        const itemToUpdateIndex: number = state.pages[pageIndex].items.findIndex(item => item.id == updatedItem.id);
        var currentPageItems = state.pages[pageIndex].items
        var newPageItems = replaceItem(updatedItem, itemToUpdateIndex, currentPageItems)
        state.pages[pageIndex].items = newPageItems
    }
};

function getCurrentPageIndex(offset: number) {
    return Math.ceil(offset / pageSize);
}

function replaceItem(item: Item, index: number, items: Array<Item>): Array<Item> {
    const copiedItems = [...items];
    copiedItems[index] = item;
    return copiedItems
}

function numberOfPages(totalCount: number) {
    if (totalCount > 0) return Math.ceil(totalCount / pageSize)
    else return 1
}

const actions = <ActionTree<State, any>>{
    loadPage: async (context, page: number) => {
        const offsetToReadFrom = (page - 1) * pageSize;
        return ItemService.getItems(offsetToReadFrom).then((paginatedResponse: PaginatedResponse) => {
            if (offsetToReadFrom < paginatedResponse.totalCount) {
                return context.commit('addPage', paginatedResponse);
            }
        });
    },
    markSold: async (context, item: Item) => {
        return ItemService.markItemSold(item).then((updatedItem: Item) => {
            context.commit('updateItem', updatedItem)
        })
    },
    markUnsold: async (context, item: Item) => {
        return ItemService.markItemUnsold(item).then((updatedItem: Item) => {
            context.commit('updateItem', updatedItem)
        })
    }
};


const SessionModule = {
    namespaced: true,
    state: new State(0, 0, pageSize, Array<Page>()),
    getters: getters,
    mutations: mutations,
    actions: actions
};

export default SessionModule;
