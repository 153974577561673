import axios from "axios";
import { Item } from './model/item';

const endpoint = "items"

export const pageSize = 20;

class ItemService {

    createItem(item: Item) {
        return axios.post("items", item);
    }

    getItems(offset: number): Promise<PaginatedResponse> {
        return axios.get<PaginatedResponse>("items", { params: { limit: pageSize, offset: offset } }).then((response) => {
            var json: PaginatedResponse = response.data;
            return new PaginatedResponse(json.count, json.totalCount, json.offset, json.limit, json.items);
        });
    }

    getItem(id: String): Promise<Item> {
        return axios.get<Item>(`items/${id}`).then((response) => {
            return response.data;
        });
    }

    markItemSold(item: Item): Promise<Item> {
        const soldAt = new Date().toISOString();
        return axios.put<Item>("items", { ...item, soldAt: soldAt }).then(response => {
            return response.data;
        });
    }

    markItemUnsold(item: Item): Promise<Item> {
        return axios.put<Item>("items", { ...item, soldAt: undefined }).then(response => {
            console.log(response.data)
            return response.data;
        });
    }
}

export interface PaginatedResponse {
    count: number
    totalCount: number
    offset: number
    limit: number
    items: Item[]
}

export class PaginatedResponse implements PaginatedResponse {
    count: number
    totalCount: number
    offset: number
    limit: number
    items: Item[]

    constructor(count: number, totalCount: number, offset: number, limit: number, items: Item[]) {
        this.count = count
        this.totalCount = totalCount
        this.offset = offset
        this.limit = limit
        this.items = items
    }

}

export default new ItemService();
