























































import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class QuickLinks extends Vue {
  cards = [
    {
      title: "List",
      description: "List new items",
      path: "/list",
      available: true,
    },
    {
      title: "Listing Sessions",
      description: "View listing sessions",
      path: "/listing-sessions",
      available: true,
    },
    {
      title: "Inventory",
      description: "Manage your inventory",
      path: "/inventory",
      available: true,
    },
    {
      title: "Images",
      description: "Upload item images",
      path: "/images",
      available: false,
    },
    {
      title: "Account Settings",
      description: "Manage your account",
      path: "/settings",
      available: false,
    },
    {
      title: "Help",
      description: "View our help page",
      path: "/help",
      available: false,
    },
    // { title: "Admin Controls", description:"Manage admin settings", path:"/admin", available:false}
  ];
}
