import Vuex from 'vuex'
import Vue from 'vue'
import AuthModule from "@/store/auth.module";
import SessionModule from "@/store/session.module";
import ItemsModule from "@/store/items.module"

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    auth: AuthModule,
    session: SessionModule,
    items: ItemsModule,
  }
})
