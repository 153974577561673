












import Vue from "vue";
import Component from "vue-class-component";
import QuickLinks from "@/components/QuickLinks.vue";

@Component({
  components: {
    QuickLinks
  }
})

export default class Home extends Vue{}
