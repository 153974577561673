import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/views/Home.vue'
import About from '@/views/About.vue'

import Axios from "axios";

Vue.use(VueRouter)

  const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    redirect: "/home"
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/login',
    name: 'Auth',
    component: () => import('../components/Auth.vue')
  },
  {
    path: '/list',
    name: 'List',
    component: () => import('../views/List.vue')
  },
  {
    path: '/listing-sessions',
    name: 'Sessions',
    component: () => import('../views/ListingSessions.vue')
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: () => import('../views/Inventory.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/about'];
  const authRequired = !publicPages.includes(to.path);

  const token = Vue.$cookies.get('token');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !token) {
    next(`/login?redirectUrl=${encodeURIComponent("/#" + to.path)}`);
  } else {
    Axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
    next();
  }
});

export default router
