import axios from 'axios';
import { v4 as uuid } from "uuid";
import { Item } from "./model/item";

const endpoint = "listingsessions";

class SessionService {

    createSession() {
        var sessionId = uuid();
        return axios.post(endpoint, (
            {
                id: sessionId
            }
        )).then(_ => this.getLatestSession())
    }

    getLatestSession(): Promise<ListingSession> {
        return this.getSessions()
            .then(sessions => {
                const latestSession = sessions.sort((n1: ListingSession, n2: ListingSession) => {
                    return Date.parse(n2.createdAt) - Date.parse(n1.createdAt)
                })[0]

                if (!latestSession) {
                    return this.createSession().then(_ => this.getLatestSession())
                }
                else {
                    return latestSession;
                }
            });
    }

    getItems(id: string): Promise<Array<Item>> {
        return axios.get<ListingSessionItems>(endpoint + "/" + id)
            .then(response => {
                return response.data.items;
            });
    }

    getSessions(): Promise<Array<ListingSession>> {
        return axios.get<ListingSessions>(endpoint, { params: { limit: 100 } })
            .then((response) => {
                return response.data.listingSessions;
            });
    }
}

interface ListingSessionItems {
    id: string
    createdAt: string
    items: Item[]
}

interface ListingSessions {
    listingSessions: ListingSession[]
}

export interface ListingSession {
    id: string
    createdAt: string,
    itemCount: number,
    soldCount: number
}

export class ListingSession implements ListingSession {
    id: string
    createdAt: string
    itemCount: number
    soldCount: number

    constructor(id: string, createdAt: string, itemCount: number, soldCount: number) {
        this.id = id;
        this.createdAt = createdAt;
        this.itemCount = itemCount;
        this.soldCount = soldCount;
    }
}

export default new SessionService();
